import React, { useState, useEffect, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactInputVerificationCode from "react-input-verification-code";

import { dataSecure } from "../DataFiles/standardText";
import {
  stateNameToAbbreviationMap,
  statesFullNameList,
} from '../DataFiles/states';
import { routerMap } from "../utils/router";

import "../MainForm/MainForm.css";
const VerificationCodePlaceHolder = "·";

const DemographicForm = (props) => {
  const { onFormValidated, setValidated, headerRef } = props;

  const [localState, setLocalState] = useState({
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    zip: '',
  });

  const [phoneError, setPhoneError] = useState("");
  const [ageError, setAgeError] = useState("");
  const [emailError, setEmailError] = useState('');
  const [zipLength, setZipLength] = useState(0);
  const [zipError, setZipError] = useState('');
  const [isPhoneValidated, setIsPhoneValidated] = useState(false);
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);

  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [isVerifyingPhone, setIsVerifyingPhone] = useState(false);
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState(null);
  const [verificationCode, setVerificationCode] = useState("");
  const [phoneCodeError, setPhoneCodeError] = useState("");

  const [showVerificationCodeModal, setShowVerificationCodeModal] =
    useState(false);

  const { verifyPhone, sendPhoneVerification, verifyOTPForPhone } = routerMap;

  const handleCloseVerificationCodeModal = () =>
    setShowVerificationCodeModal(false);
  const handleShowVerificationCodeModal = async () => {
    const result = await sendPhoneVerification(localState.phone, setPhoneError);
    if (result) {
      setShowVerificationCodeModal(true);
    }
  };

  const handleVerifyPhoneCode = async () => {
    if (
      verificationCode.length < 6 ||
      verificationCode.includes(VerificationCodePlaceHolder)
    ) {
      setPhoneCodeError("Please enter a 6 digit verification code");
      return;
    }
    setIsVerifyingPhone(true);
    const result = await verifyOTPForPhone(
      localState.phone,
      verificationCode,
      setPhoneCodeError
    );
    setIsVerifyingPhone(false);
    if (result) {
      setIsPhoneVerified(true);
      setVerifiedPhoneNumber(localState.phone);
      handleCloseVerificationCodeModal();
    } else {
      setIsPhoneVerified(false);
    }
  };

  const phoneMask = (phone) => {
    return phone
      .replace(/\D/g, "")
      .replace(/^(\d)/, "($1")
      .replace(/^(\(\d{3})(\d)/, "$1) $2")
      .replace(/(\d{3})(\d{1,4})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  };

  const validatePhone = async (phone) => {
    setIsValidatingPhone(true);
    setPhoneError("");
    if (phone.length === 10) {
      const result = await verifyPhone(phone, setPhoneError);
      setIsValidatingPhone(false);
      setIsPhoneValidated(result);
      return result;
    } else {
      setPhoneError("Please enter your cell phone number");
      setIsPhoneValidated(false);
      setIsValidatingPhone(false);
      return false;
    }
  };

  const handleChange = async (e) => {
    const now = new Date();
    const localDob = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    switch (e.target.name) {
      case 'zip':
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        setZipLength(e.target.value.length);
        break;
      case "phone":
        e.target.value = phoneMask(e.target.value);
        const cleanedPhone = e.target.value.replace(/\D/g, "");
        if (cleanedPhone.length === 10) {
          if (!isValidatingPhone) {
            const isValidPhone = await validatePhone(cleanedPhone);
            if (isValidPhone) {
              setLocalState((prev) => ({
                ...prev,
                [e.target.name]: cleanedPhone,
              }));
            }
          }
        } else {
          setIsPhoneValidated(false);
        }
        break;
      case "dobMonth":
        const monthValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${monthValue}/${localDob[1]}/${localDob[2]}`,
        }));
        break;
      case "dobDate":
        const dateValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${localDob[0]}/${dateValue}/${localDob[2]}`,
        }));
        break;
      case "dobYear":
        const yearValue = e.target.value;
        setLocalState((prev) => ({
          ...prev,
          dob: `${localDob[0]}/${localDob[1]}/${yearValue}`,
        }));
        break;
      default:
        setLocalState({
          ...localState,
          [e.target.name]: e.target.value,
        });
        break;
    }
  };

  const handleAgeBlur = () => {
    if (localState.dob.length < 8) {
      setAgeError("Date of birth must be in valid format");
      return;
    }
    ageCheck();
  };

  const ageCheck = () => {
    const now = new Date();
    const eighteenYearsAgo = new Date(
      now.getFullYear() - 18,
      now.getMonth(),
      now.getDate()
    );
    const tooOld = new Date(
      now.getFullYear() - 110,
      now.getMonth(),
      now.getDate()
    );
    const dobArray = localState.dob
      ? localState.dob.split("/")
      : [1, 1, now.getFullYear()];
    const dobMonth = dobArray[0];
    const dobDate = dobArray[1];
    const dobYear = dobArray[2];
    const dob = new Date(dobYear, dobMonth - 1, dobDate);
    if (dob > eighteenYearsAgo) {
      setAgeError(
        "This service is only available for people over the age of 18."
      );
      setValidated(false);
      return false;
    } else if (dob < tooOld) {
      setAgeError("Please enter a valid date of birth");
      setValidated(false);
      return false;
    } else {
      setAgeError("");
      return true;
    }
  };

  const handleZipBlur = () => {
    if (zipLength < 5) {
      setZipError('Please enter a valid zip code');
    } else {
      setZipError('');
    }
  };

  const handleEmailBlur = () => {
    const regex = /[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z0-9]+/;
    const regMatch = regex.test(localState.email);
    if (!regMatch) {
      setEmailError('Please enter a valid email address');
    } else {
      setEmailError('');
    }
  };

  useEffect(() => {
    if (
      localState.firstName.length &&
      localState.lastName.length &&
      localState.email.length &&
      localState.phone.length &&
      localState.address.length &&
      localState.city.length &&
      localState.state.length &&
      localState.zip.length &&
      localState.dob.length >= 8 &&
      !ageError &&
      !zipError &&
      !phoneError &&
      !emailError &&
      isPhoneVerified &&
      isPhoneValidated
    ) {
      if (localState.phone !== verifiedPhoneNumber) {
        setPhoneError("Please validate your phone number");
        // if they enter a different phone number after, we'll
        // need to have them re-verify their new phone
        setIsPhoneVerified(false);
        setValidated(false);
        return;
      }
      setValidated(true);
      onFormValidated(localState);
    } else {
      setValidated(false);
    }
  }, [
    localState,
    ageError,
    phoneError,
    zipError,
    emailError,
    onFormValidated,
    isPhoneValidated,
    isPhoneVerified,
    verifiedPhoneNumber,
    setValidated,
  ]);

  useEffect(() => {
    headerRef.current.scrollIntoView();
  }, []);

  return (
    <div className="demographicContainer">
      <div className="checkoutForm">
        <div>
          <div className="dataSecureText">
            {dataSecure.header} For more information, click{" "}
            <a
              href="https://customerconsents.s3.amazonaws.com/Beluga_Health_PA_Privacy_Policy.pdf"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </div>
        </div>

        <div className="demographicSectionLabel">Personal Information</div>

        <div className='demographicSubRow'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>First name</div>
            <input
              name='firstName'
              placeholder='First Name'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
          <div className='spacer' />
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Last name</div>
            <input
              name='lastName'
              placeholder='Last Name'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
        </div>





        <div className="demographicSubRow emailAndPhone">
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Email</div>
            <input
              name='email'
              placeholder='Email'
              onChange={handleChange}
              onBlur={handleEmailBlur}
              className={
                emailError
                  ? 'checkoutInput checkoutInputError'
                  : 'checkoutInput'
              }
            />
            {emailError && <div className='checkoutError'>{emailError}</div>}
          </div>
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">
              Mobile phone number
            </div>
            <div className="phoneInputVerificationHolder">
              <input
                name="phone"
                placeholder="Phone"
                type="tel"
                onChange={handleChange}
                // maxLength='10'
                className={
                  phoneError
                    ? "checkoutInput checkoutInputError"
                    : "checkoutInput"
                }
              />
              {isPhoneVerified ? (
                <div className="phoneVerifiedIndication">
                  <div className="checkMark"></div>
                </div>
              ) : (
                <button
                  disabled={!isPhoneValidated}
                  className="phoneVerificationButton orderButton"
                  onClick={handleShowVerificationCodeModal}
                >
                  Verify
                </button>
              )}
            </div>
            {phoneError && <div className="checkoutError">{phoneError}</div>}
          </div>

          <Modal
            id="phone-verification-modal"
            show={showVerificationCodeModal}
            onHide={handleCloseVerificationCodeModal}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title>VERIFICATION</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="my-2">
                Please enter the verification code that we sent to your phone
                number.
              </div>
              <div className="custom-styles">
                <ReactInputVerificationCode
                  length={6}
                  onChange={(value) => {
                    if (value && !value.includes("·")) {
                      setVerificationCode(value);
                      // TODO: do we want to automatically submit?
                      // handleVerifyPhoneCode(value);
                    }
                  }}
                />
              </div>
              {phoneCodeError && (
                <div className="checkoutError">{phoneCodeError}</div>
              )}
              {/* <div className="verificationCodeTimer"> 30 secs</div> */}
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={isVerifyingPhone}
                variant="secondary"
                onClick={handleCloseVerificationCodeModal}
              >
                Close
              </Button>
              <Button
                disabled={isVerifyingPhone || verificationCode.length < 6}
                variant="primary"
                onClick={handleVerifyPhoneCode}
              >
                Verify
              </Button>
            </Modal.Footer>
          </Modal>

          {/* this is intended */}
          <div className="innerSubRow"></div>
        </div>
        <div className="demographicSubRow dobAndAddress">
          <div className="innerSubRow">
            <div className="formFieldSmall checkoutField">Date of birth</div>
            <div className="dobInputs">
              <input
                name="dobMonth"
                placeholder="MM"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobDate"
                placeholder="DD"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputMini checkoutInputError"
                    : "checkoutInputMini"
                }
                maxLength="2"
              />
              <span className="expirySlash">/</span>
              <input
                name="dobYear"
                placeholder="YYYY"
                onChange={handleChange}
                onBlur={handleAgeBlur}
                className={
                  ageError
                    ? "checkoutInputShort checkoutInputError"
                    : "checkoutInputShort"
                }
                maxLength="4"
              />
            </div>
            {ageError && <div className="checkoutError">{ageError}</div>}
          </div>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Address</div>
            <input
              name='address'
              placeholder='Address'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
          {/* this is intended */}
          {/* <div className="innerSubRow"></div> */}
        </div>
        <div className='demographicSubRow'>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>City</div>
            <input
              name='city'
              placeholder='City'
              onChange={handleChange}
              className='checkoutInput'
            />
          </div>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>State</div>
            <div className='checkoutInputShort state'>
              <label>
                <select
                  name='state'
                  id='state-select'
                  onChange={handleChange}
                  required
                  defaultValue=''
                >
                  <option value='' disabled>
                    State
                  </option>
                  {statesFullNameList.map((name) => {
                    return (
                      <Fragment key={name}>
                        <option
                          value={stateNameToAbbreviationMap[name]}
                        >
                          {name}
                        </option>
                      </Fragment>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className='innerSubRow'>
            <div className='formFieldSmall checkoutField'>Zip Code</div>
            <input
              name='zip'
              placeholder='Zip'
              onChange={handleChange}
              onBlur={handleZipBlur}
              className={
                zipError
                  ? 'checkoutInputShort zip checkoutInputError'
                  : 'checkoutInputShort zip'
              }
              maxLength='5'
            />
            {zipError && <div className='checkoutError'>{zipError}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DemographicForm;
